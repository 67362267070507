<template>
  <v-col md="12" style="padding: 0">
    <hr />
    <v-card
      flat
      style="
            top: 0;
            background: white;
            border-bottom: 1.5px solid indigo;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
          "
    >
      <div
        style="
              display: flex;
              jsutify-content: center;
              align-items: center;
              margin-left: 10px;
            "
      >
        <v-toolbar-title class="overline"
          ><p
            style="font-size: 16px; margin: 0; font-weight: bold; color:indigo;"
          >
            Manage Lot
          </p>
        </v-toolbar-title>
      </div>

      <div style="display: flex">
        <v-btn
          outlined
          elevation="1"
          color="indigo"
          class="indigo--text font-weight-bold ma-2"
          small
          @click="addLine"
        >
          Manual Add
        </v-btn>
        <v-btn
          outlined
          elevation="1"
          color="indigo"
          class="indigo--text font-weight-bold ma-2"
          small
          @click="randomGenerate"
        >
          Random Generate
        </v-btn>
        <v-btn
          outlined
          elevation="1"
          color="indigo"
          class="indigo--text font-weight-bold ma-2"
          small
          @click="addSequence"
        >
          Sequence Generate
        </v-btn>
        <!-- <v-btn
          small
          tile
          color="black"
          class="ma-2"
          style="color: white"
          @click="save"
        >
          Simpan
        </v-btn> -->
      </div>
    </v-card>
    <!-- ------------------------ Owned lot ---------------------------------- -->
    <v-card
      v-if="lot !== null"
      flat
      class=" mx-auto"
      style="padding:0; width:99%; margin-top:20px; margin-bottom:10px;"
    >
      <v-toolbar-title class="overline">
        <p
          style="font-size:14px;margin:0; padding: 5px; font-weight:bold; color:indigo;text-align:center;"
        >
          Owned LOT
        </p>
      </v-toolbar-title>
      <!-- <v-col md="3" style="padding: 0">
        <div style="padding: 0 10px">
          <v-col cols="6" style="padding: 0">
            <p
              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
            >
              Total Aset
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              v-if="lot !== null"
              dense
              outlined
              readonly
              :value="lot.total_stock"
            />
          </v-col>
        </div>
      </v-col> -->
      <v-data-table
        fixed-header
        height="33vh"
        :items="lot.lot"
        :headers="headers"
        class="elevation-1 mx-auto"
        style="width:100%; margin-bottom:10px; border:1px solid #e0e0e0;cursor:pointer;"
        :items-per-page="10"
        @click:row="rowClick"
      >
        <template v-slot:[`item.total`]>
          <div style="font-size:12px; padding: 0; display: flex; ">
            1
          </div>
        </template>
        <template v-slot:[`item.current_location_name`]="{ item }">
          <div style="font-size:12px; padding: 0; display: flex; ">
            {{ item.current_company_name }} - ({{ item.current_location_name }})
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div
            :style="
              `font-size:12px; padding: 0; display: flex; color:${statusColor(
                item.status
              )};`
            "
          >
            {{ lotStatus(item.status) }}
          </div>
        </template>
        <!-- <template v-slot:[`item.department`]="{ item }">
          <div style="font-size:12px; padding: 0; display: flex; ">
            {{ item.department.name }}
          </div>
        </template> -->
        <template v-slot:[`item.action`]="{ item }">
          <div
            v-if="item.status !== 2"
            style="font-size:12px; padding: 0; display: flex; "
          >
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    icon
                    @click.stop="qrCode(item)"
                  >
                    <v-icon color="indigo">mdi-qrcode</v-icon>
                  </v-btn>
                </template>
                <span>QR Code</span>
              </v-tooltip>
            </div>
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    icon
                    color="indigo"
                    @click.stop="update(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </div>
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    icon
                    color="red lighten-2"
                    @click.stop="remove(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <!-- ------------------------ Transfered lot ---------------------------------- -->
    <!-- <v-card
      v-if="lot !== null"
      flat
      class=" mx-auto"
      style="padding:0; width:99%; margin-top:20px; margin-bottom:10px;"
    >
      <v-toolbar-title class="overline">
        <p
          style="font-size:14px;margin:0; padding: 5px; font-weight:bold; color:indigo;text-align:center;"
        >
          Transfered LOT
        </p>
      </v-toolbar-title>
      <v-data-table
        fixed-header
        height="33vh"
        :headers="headers"
        class="elevation-1 mx-auto"
        style="width:100%; margin-bottom:10px; border:1px solid #e0e0e0;cursor:pointer;"
        :items-per-page="10"
      >
        <template v-slot:[`item.total`]>
          <div style="font-size:12px; padding: 0; display: flex; ">
            1
          </div>
        </template>
        <template v-slot:[`item.current_location_name`]="{ item }">
          <div style="font-size:12px; padding: 0; display: flex; ">
            {{ item.current_company_name }} - ({{ item.current_location_name }})
          </div>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <div style="font-size:12px; padding: 0; display: flex; ">
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    icon
                    @click.stop="qrCode(item)"
                  >
                    <v-icon color="indigo">mdi-qrcode</v-icon>
                  </v-btn>
                </template>
                <span>QR Code</span>
              </v-tooltip>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card> -->

    <line-save
      @loadPage="loadList"
      :detailLot="lot"
      :assetDetail="assetDetail"
    />
    <random-generate
      @loadPage="loadList"
      :detailLot="lot"
      :assetDetail="assetDetail"
    />
    <add-sequence
      @loadPage="loadList"
      :detailLot="lot"
      :assetDetail="assetDetail"
    />
    <qr-code :selectedLot="selected" />
    <div style="height:0; overflow:hidden;" v-if="form !== null">
      <v-dialog v-model="dialog" width="500">
        <v-form
          ref="entryForm"
          @submit.prevent="submit"
          style="position: relative;"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Edit Lot
            </v-card-title>

            <v-card-text class="d-flex justify-center align-center">
              <v-col md="12" style="padding: 0; position:relative; top:20px;">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Kode Lot
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-text-field
                      v-model="form.lot_no"
                      outlined
                      :rules="codeRules"
                    />
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Department
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0;">
                    <v-autocomplete
                      v-model="form.department"
                      :items="getDropdownDepartment"
                      item-text="name"
                      item-value="id"
                      return-object
                      outlined
                      dense
                      style="margin: 0"
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                color="error"
                text
                outlined
                @click="close"
                :loading="loading"
              >
                Batal
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                type="submit"
                text
                outlined
                :loading="loading"
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </div>
  </v-col>
</template>

<script>
import axios from 'axios'
import buildType from '../../../../services/buildType'
import { mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  props: ['lot', 'assetDetail'],
  components: {
    // QrCode: () => import('./LotQrcode'),
    // QrCode: () => import('./QRCode'),
    // QrCode: () => import('./LotQrcodeV2'),
    QrCode: () => import('./QRvuehtml2pdf'),
    LineSave: () => import('./LineSave'),
    RandomGenerate: () => import('./RandomGenerate'),
    AddSequence: () => import('./AddSequence')
  },
  data() {
    return {
      asset: buildType.apiURL('asset'),
      headers: [
        { text: 'Lot', value: 'lot_no', align: 'left', sortable: false },
        { text: 'total', value: 'total', align: 'left', sortable: false },
        {
          text: 'Owned by',
          value: 'company_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Department',
          value: 'department_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'location',
          value: 'location_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Cr. location',
          value: 'current_location_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Purchased',
          value: 'purchase_date',
          align: 'left',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status',
          align: 'left',
          sortable: false
        },
        {
          text: '',
          value: 'action',
          align: 'left',
          sortable: false
        }
      ],
      selected: null,
      form: null,
      dialog: false,
      loading: false,
      codeRules: []
    }
  },
  computed: {
    ...mapGetters(['getDropdownDepartment'])
  },
  methods: {
    ...mapMutations(['setAssetLotCompanyId']),
    ...mapActions(['dropdownDepartment']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    addLine() {
      setTimeout(() => {
        document.getElementById('addLot').click()
      }, 300)
    },
    randomGenerate() {
      setTimeout(() => {
        document.getElementById('randomGenerateLot').click()
      }, 300)
    },
    addSequence() {
      setTimeout(() => {
        document.getElementById('addSequenceLot').click()
      }, 300)
    },
    loadList() {
      this.$emit('loadPage')
    },
    remove(pItem) {
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(res => {
        if (res.isConfirmed) {
          axios
            .delete(`${this.asset}asset/lot/delete/${pItem.id}`)
            .then(res => {
              this.showMsgDialog('success', res.data.status_msg, 'false')
              this.loadList()
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          this.actionValue = 0
        }
      })
    },
    rowClick(pItem) {
      this.setAssetLotCompanyId(pItem.company_id)
      setTimeout(() => {
        this.$router.push(
          `/asset-management/aset-kepemilikan/lot/detail/${pItem.id}`
        )
      }, 300)
    },
    qrCode(pItem) {
      const item = pItem
      const name = item.name.toUpperCase()
      const arrName = name.split('')
      console.log(arrName)
      for (let i = 0; i < arrName.length; i++) {
        if (arrName[i] === '-') {
          arrName[i] = arrName[i] + '&NoBreak;'
        }
      }
      const newName = arrName.join('')
      item.name = newName
      console.log(item)
      this.selected = item
      setTimeout(() => {
        document.getElementById('lotQrcode').click()
      }, 300)
    },
    async update(pItem) {
      this.form = {
        act: 'update',
        id: pItem.id,
        lot_no: pItem.lot_no,
        department: {
          id: pItem.department_id,
          name: pItem.department_name
        }
      }
      await this.dropdownDepartment()
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },

    submit() {
      this.codeRules = [v => !!v || 'Code is required']
      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      this.save(this.form)
    },
    save(form) {
      const id = this.assetDetail.code.slice(
        1,
        this.assetDetail.code.indexOf('.')
      )
      const name = this.getCompany(Number(id))
      const newform = {
        act: 'update',
        department_id: form.department.id,
        department_name: form.department.name,
        company_id: Number(id),
        company_name: name,
        id: form.id,
        lot_no: form.lot_no
      }
      console.log(newform)
      this.loading = true
      axios
        .post(`${this.asset}asset/lot/update`, newform)
        .then(res => {
          if (res.data.status_code !== '-99') {
            setTimeout(() => {
              this.showMsgDialog('success', res.data.status_msg, false)
              this.close()
            }, 1000)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }

            this.loading = false
          }
        })
        .catch(err => {
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    close() {
      this.loading = false
      this.dialog = false
      this.form = null
      this.loadList()
    },
    lotStatus(pId) {
      switch (pId) {
        case -1:
          return 'Gone'
        case 1:
          return 'Available'

        case 2:
          return 'Sold'
      }
    },
    statusColor(pId) {
      switch (pId) {
        case -1:
          return 'red'
        case 1:
          return 'Green'

        case 2:
          return 'red'
      }
    },

    getCompany(val) {
      switch (val) {
        case 1:
          return 'PT TIRTA MAS PERKASA (TMP)'
        case 2:
          return 'PT INDOMULTIMAS PERKASA (IMP)'
        case 3:
          return 'PT GRAHAMAS INTITIRTA (GIT)'
        case 4:
          return 'PT KENCANA ABADI JAYA (KAJ)'
        case 5:
          return 'PT WAHANA INTI MAS (WIM)'
        case 6:
          return 'PT SANQUA MULTI INTERNASIONAL (SMI)'
        default:
          return null
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
